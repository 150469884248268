.conference {
  button {
    width: 30%;
  }
  p {
    color: #ffffff;
    margin: 40px 0;
  }
}
.conference {
  .MuiPickersDay-day {
   span {
     color: #ffffff!important;
   }
  }
}
.calendar-wrap {
  display: inline-flex;
  box-shadow: 0px 4px 10px rgba(255, 255, 255, 0.4);
}
.calendar-info {
  background: #1C4A47;
  color: #ffffff;
  text-align: center;
  padding: 15px 40px;
  span {
    display: block;
  }
}
.calendar-sum {
  font-size: 70px;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 15px;
}
.not-booked {
  margin-top: 50px;
}


.conference-modal-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 50px 0;
}
.conference-modal-form {
  width: 400px;
  button {
    width: 200px;
    margin-top: 25px;
  }
  .textfield {
    margin-top: 20px;
    &:first-child {
      margin-top: 0;
    }
  }
}
.react-calendar__tile--active {
  background: #1C4A47!important;
  border-radius: 50px;
}
