.item {
  padding: 100px 0;
  overflow-x: hidden;
  button {
    margin-top: 32px;
    font-weight: 500;
    border: 1px solid #FFFFFF;
    background: transparent;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 10px 20px;
    &:hover {
      background: #1C4946;
    }
    span {
      &:last-child {
        display: block;
        width: 25px;
        height: 0.5px;
        background-color: #fff;
        margin-left: 10px;
      }
    }
  }
  &-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      max-width: 100%;
    }
  }
  &-right {
    width: 50%;
    &__blur {
      margin-top: 30px;
      background: rgba(255, 255, 255, 0.1);
      filter: blur(60px);
      width: 100%;
      height: 40px;
    }
  }
  &-left {
    width: 40%;
    div {
      font-size: 60px;
      color: #ffffff;
    }
  }
  &.variant-1 {
    background: linear-gradient(111.59deg, #1C4A47 1.72%, #1B2E28 104.19%);
  }
  &.variant-2 {
    background: linear-gradient(64.79deg, #1C4946 -1.84%, #1B2E28 111.12%);
    .item-left {
      width: 40%;
      text-align: right;
    }
    button {
      margin-left: auto;
    }
  }
}
.item-left {
  div {
    font-weight: bold;
  }

}


.close-icon {
  z-index: 9999;
}
.finalist-modal-map {
  position: relative;
  &__title {
    position: absolute;
    top: 50%;
    color: #ffffff;
    left: 50%;
    transform: translate(-50%, -50%)
  }
  svg {
    width: 100%!important;
    height: 100%!important;
  }
}


.selected-file {
  color: #ffffff;
  margin: 15px 0;
  display: block;
  font-size: 16px;
}
.upload-startup-modal {
  background: rgba(255, 255, 255, 0.34);
  display: flex;
  cursor: pointer;
  align-items: center;
  height: 50px;
  position: relative;
  span {
    color: #ffffff;
    font-size: 13px;
    padding-left: 10px;
  }
  svg {
    position: absolute;
    right: 10px;
    width: 20px!important;
    height: 20px!important;
  }
}


.competition {
  padding: 40px 0;
  color: #ffffff;
  .textfield {
    margin-top: 20px;
  }
  &-title {
    font-weight: bold;
    text-align: center;
  }
  &-desc {
    font-size: 14px;
    text-align: center;
    margin: 20px 0;
  }
  button {
      border: 1px solid #fff;
      color: #ffffff;
      background: transparent;
      padding: 12px 40px;
      margin-top: 20px;
  }
}
