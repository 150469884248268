.event {
  .title-event {
    margin-bottom: 50px;
    color: #ffffff;
    font-weight: bold;
    font-size: 50px;
  }
  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .event-item {
    background-color: #fff;
    opacity: 0.9;
    display: flex;
    padding: 30px 0;
    justify-content: space-between;
    margin-bottom: 25px;
  }
  &-left {
    margin-left: 50px;
    span {
      color: #808E9B;
      font-size: 17px;
      display: block;
    }
    &-title {
      font-weight: bold;
    }
  }
  &-button {
    width: 200px;
    height: 40px;
    margin-top: 20px;
    position: relative;
    &:before {
      content: "";
      position: absolute;
      width: 15%;
      height: 1px;
      background-color: #1C4A47;
      right: 20px;
      top: 50%;
    }
    button {
      border: 1px solid #1C4A47;
      color: #1C4A47;
      height: 100%;
      background: transparent;
      width: 100%;
      padding-right: 15%;
    }
  }
  &-date {
    display: flex;
    align-items: center;
    font-size: 17px;
    margin-top: 5px;
  }
  &-slot {
    display: flex;
    align-items: center;
    margin-top: 5px;
  }
  &-right {
    padding-right: 20px;
    display: flex;
    align-items: center;
    img {
      margin-right: 20px;
    }
  }
}


.event-modal {
  padding: 30px 50px;
  &-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
  ;
  }
  .event-modal-card-title {
    font-weight: bold;
    margin-top: 5px 0;
  }
  &-title {
    font-weight: bold;
    color: #ffffff;
    font-size: 40px;
  }
  &-desc {
    color: #ffffff;
    margin-top:20px;
    margin-bottom: 20px;
    display: inline-block;
  }
  &-right {
    background-color: #fff;
    opacity: 0.9;
    margin-right: 50px;
    width: 30%;
    text-align: center;
    .event-modal-card {
      padding: 20px 0;
      width: 70%;
      margin: 0 auto;
      text-align: center;
      span {
        font-size: 16px;
        color: #000000;
      }
      .event-modal-moder-name {
        font-weight: bold;
        margin-bottom: 20px;
        margin-top: 10px;
      }
    }
    img {
      width: 150px;
      height: 150px;
    }
    .event-slot {
      justify-content: center;
      font-weight: bold;
      span {
        font-weight: normal;
      }
    }
    .event-date {
      justify-content: center;
      font-weight: bold;
      span {
        font-weight: normal;
      }
    }
  }
  &-left {
    form {
      display: block;
      width: 350px;
      button {
        width: 170px;
        margin-top: 40px;
      }
    }
    .textfield {
      margin-bottom: 20px;
    }
  }
}


.newButton {
  position: relative;
  border: 1px solid #fff;
  color: #ffffff;
  height: 50px;
  background: transparent;
  padding-right: 20px;
  &::before {
    content: "";
    position: absolute;
    width: 15%;
    height: 1px;
    background-color: #fff;
    right: 20px;
    top: 50%;
  }
}
