.fablab-content {
  .fablab-technologies {
    .subtitle {
      margin-top: 40px;
      margin-bottom: 20px;
    }
    &__wrap {
      display: grid;
      grid-template-columns: repeat(4, 25%);
      grid-column-gap: 20px;
    }
    &__item {
      &-bg {
        height: 250px;
        background-size: cover!important;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 2;
        color: #ffffff;
        &:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: #000;
          opacity: 0.5;
          z-index: -1;
        }
      }
    }
  }
  p {
    color: #ffffff;
    margin-bottom: 30px;
  }
  &__abonements {
    &-wrap {
      display: flex;
      justify-content: space-between;
      .subtitle-desc {
        margin: 20px 0;
      }
      p {
        line-height: 2.5;
      }
    }
    &-left {
      width: 50%;
    }
    &-img {
      width: 40%;
      margin-left: 5%;
    }
    img {
      max-width: 100%;
    }
  }
}
.fablab-img, .youthlab-img {
  text-align: right;
  width: 60%;
  height: 250px;
  margin-left: 30px;
}
.custom-event-modal-wrap {
  margin-top: 20px;
  img {
    max-width: 100%;
    border-radius: 12px;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
