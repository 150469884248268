@import "~animate.css";
@import "header";
@import "global";
@import "content";
@import "ecosystem";
@import "conference";
@import "fablab";
@import "startup";
@import "event";
@import "components";
@import "media";

.swiper-wrap {
  background: linear-gradient(111.59deg, #1c4a47 1.72%, #1b2e28 104.19%);
  color: #ffffff;
  height: 500px;
  margin: 100px 0;
  .swiper-slide {
    height: 250px;
    border: 1px solid #fff;
    width: 100%;
    transition: 0.25s ease height;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url("../assets/bg.png") no-repeat;
    opacity: 0.5;
    &.swiper-slide-active {
      height: 500px;
      transition: 0.25s ease height;
      opacity: 1;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1;
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #000;
        opacity: 0.8;
        z-index: -1;
      }
    }
  }
}

.swiper-wrapper {
  display: flex;
  align-items: center;
}

.main-page {
  background: linear-gradient(82.98deg, #1c4a47 -12.04%, #1b2e28 101.77%);
}

.footer {
  color: #ffffff;
  text-align: center;
  padding-bottom: 60px;
  &__socials {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    img {
      width: 35px;
      height: 35px;
    }
  }
}

.fablab-course {
  width: 70%;
  margin: 0 auto;
  text-align: center;
  span {
    font-size: 17px;
    font-weight: 300;
    margin-bottom: 20px;
    display: block;
  }
  div {
    font-weight: bold;
    font-size: 25px;
    margin-bottom: 20px;
  }
  p {
    font-size: 16px;
  }
  button {
    margin-top: 20px;
    width: 220px;
  }
}

.price {
  color: #ffffff;
  &__content {
    display: flex;
    background: #193330;
    margin-top: 2px;
    span {
      display: block;
      margin-left: 5px;
    }
  }
}

.fablab {
  .event-modal {
    img {
      width: 200px;
    }
  }
}

/* Portfolio  */

.portfolio {
  &-content {
    input {
      width: 350px;
      height: 40px;
      background: rgba(255, 255, 255, 0.1);
      text-indent: 15px;
      color: #c4c4c4;
      border: 0;
      outline: none;
      margin-top: 50px;
    }
  }
  &-startups,
  &-investors,
  &-mentors {
    margin-top: 60px;
    &__wrap {
      display: flex;
      margin-top: 100px;
    }
    .people-card {
      width: 100%;
    }
  }
  &-investors,
  &-mentors {
    margin-top: 200px;
  }
}

.auth {
  &-copyright {
    color: #ffffff;
    font-size: 12px;
    text-align: center;
    font-weight: 300;
    margin-top: 40px;
    margin-bottom: 25px;
    .auth-copyright__condition,
    span {
      font-weight: 500;
    }
  }
  .MuiTypography-body1 {
    font-size: 12px;
    font-weight: 300;
  }
  .MuiFormGroup-root {
    flex-direction: row;
    color: #ffffff;
    margin-bottom: 20px;
  }
  .MuiRadio-root {
    color: #ffffff;
  }
  .MuiRadio-colorSecondary.Mui-checked {
    color: #ffffff;
  }
  .MuiFormLabel-root {
    color: #ffffff;
    font-size: 16px;
    margin-bottom: 15px;
  }
  &-title {
    font-size: 25px;
    color: #ffffff;
    font-weight: bold;
  }
  .textfield {
    margin-bottom: 20px;
  }

  &-forgot {
    text-align: right;
    font-size: 12px;
    color: #ffffff;
    margin-bottom: 30px;
  }
  &-btn {
    text-align: center;
    margin: 0 auto;
    button {
      background: transparent;
      width: 100%;
      padding: 0 25px;
      cursor: pointer;
      height: 40px;
      color: #ffffff;
      border: 1px solid #fff;
    }
  }
  &-subtitle {
    font-size: 15px;
    margin-bottom: 40px;
    margin-top: 20px;
    color: #ffffff;
    font-weight: normal;
    cursor: pointer;
    span {
      position: relative;
      &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: #fff;
        bottom: 0;
        left: 0;
      }
    }
  }
}

.profile {
  padding-bottom: 80px;
  a {
    color: #ffffff;
    text-decoration: underline;
  }
  .textfield {
    margin-bottom: 20px;
  }

  .upload {
    cursor: pointer;
    input {
      display: none;
    }
  }
  .button-wrapper {
    max-width: 80%;
    margin: 0 auto;
  }
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
    img {
      width: 200px;
    }
  }
  &-field {
    margin-bottom: 30px;
  }
  &-content {
    width: 80%;
    background-color: #fff;
    display: flex;
    align-items: center;
    margin-top: 50px;
    margin-left: 150px;
    padding: 30px 50px;
    label {
      font-weight: 600;
      font-size: 20px;
      display: block;
      margin-bottom: 5px;
    }
    form,
    select,
    textarea,
    input {
      width: 100%;
      display: block;
    }
    select,
    input {
      height: 40px;
    }
    textarea {
      height: 300px;
    }
    .investor-textarea {
      height: 200px;
    }
  }
  &-form {
    &__img {
      text-align: center;
      width: 150px;
      height: 150px;
      margin: 0 auto;
      border-radius: 50%;
      label {
        display: block;
        width: 100%;
        height: 100%;
      }
      img {
        border-radius: 50%;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    form {
      max-width: 360px;
      margin: 0 auto;
    }
  }
  &-top {
    margin-left: 60px;
    img {
      width: 150px;
      margin-right: 20px;
    }
  }
  &-img {
    display: flex;
    align-items: center;
    img {
      border-radius: 50%;
      object-fit: cover;
      max-width: 100%;
      width: 150px;
      height: 150px;
      display: block;
    }
    div {
      color: #ffffff;
      div {
        &:first-child {
          margin-bottom: 5px;
        }
        &:last-child {
          text-decoration: underline;
          font-size: 15px;
        }
      }
    }
  }
  &-title {
    margin-bottom: 30px;
    font-size: 32px;
    margin-top: 40px;
    color: #ffffff;
    font-weight: bold;
  }
  &-empty {
    width: 560px;
    margin: 50px auto;
    background-color: #fff;
    padding: 60px;
    text-align: center;
    &__subtitle {
      font-size: 15px;
      margin-bottom: 20px;
    }
    &__title {
      font-size: 15px;
      margin-bottom: 20px;
      color: #000000;
      font-weight: bold;
    }
    button {
      background: transparent;
      color: #1c4a47;
      font-size: 16px;
      border: 1px solid #1c4a47;
      padding: 0 30px;
      height: 45px;
      cursor: pointer;
    }
  }
}

.startup {
  //.swiper-container {
  //  overflow: initial!important;
  //}
  &__content {
    margin-top: 100px;
  }
  &__finalists {
    margin-top: 300px;
    .subtitle {
      margin-bottom: 30px;
    }
    &__wrap {
      display: grid;
      grid-template-columns: repeat(4, 25%);
      grid-column-gap: 10px;
      .people-card {
        margin-bottom: 100px;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .close-icon {
    svg {
      width: 25px;
      height: 25px;
    }
  }
  .calendar-wrap {
    box-shadow: none;
  }
  .conference-modal-wrap {
    flex-direction: column-reverse;
    .conference-modal-form {
      width: 100%;
    }
    .calendar-info {
      display: none;
    }
    .react-calendar {
      padding: 0 20px;
      margin-bottom: 20px;
    }
  }
  .profile {
    &-top {
      margin-left: 0;
    }
    &-empty {
      width: 100%;
    }
    &-content {
      width: 100%;
      margin-left: 0;
    }
  }
}

.custom-swiper-item {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover !important;
}

.item {
  p {
    color: #fff;
    font-size: 15p;
    margin-top: 20px;
  }
}

.lesson-content {
  .item-wrap {
    align-items: flex-start;
  }
}

.lesson-event-title {
  font-size: 30px;
  margin-bottom: 40px;
  color: #fff;
}

.MuiPagination-root {
  text-align: center;
  margin: 0 auto;
  justify-content: center;
  display: flex;
}
.MuiPagination-ul {
  text-align: center;
  li {
    button {
      color: #fff !important;
    }
  }
}

.lesson-slide-title {
  margin-bottom: 40px;
  color: #ffffff;
  font-weight: bold;
  font-size: 28px;
  margin-top: 120px;
}
.lesson-slide {
  text-align: center;
  color: #ffffff;
  &__img {
    width: 200px;
    margin: 0 auto;
    position: relative;
    a {
      position: absolute;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 999;
      font-size: 15px;
      text-decoration: underline;
      background-color: #000;
      cursor: pointer;
      color: #ffffff;
    }
  }
  img {
    width: 100%;

    max-height: 250px;
    object-fit: cover;
  }
}

@media screen and (max-width: 992px) {
  .ecosystem-content {
    flex-direction: column;
  }
  .ecosystem__left-img {
    width: 100%!important;
    height: 100%!important;
  }
  .ecosystem__title {
    font-size: 30px;
  }
  .ecosystem__desc {
    font-size: 15px;
  }
  .ecosystem__left, .ecosystem__right {
    width: 100%;
    height: 100%;
  }
  .ecosystem__right-title {
    width: 100%!important;
    margin-left: 0!important;
  }
  .ecosystem__buttons {
    margin-bottom: 25px;
  }
  .lesson {
    .swiper-slide {
      justify-content: center;
    }
  }
  .lesson-content {
    .item {
      padding: 50px 0;
      &-left {
        p {
          text-align: center;
          margin-bottom: 20px;
        }
      }
    }
  }
}

.filter-sphere {
  width: 30%;
  margin-top: 20px;
}

.reset-password {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 500px;
  margin: 0 auto;
  .auth-title {
    text-align: center;
    margin-bottom: 30px;
    display: block !important;
  }
  .textfield {
    margin-bottom: 20px;
  }
  input {
    width: 100%;
  }
  &-wrap {
    width: 100%;
  }
}

.success-activate {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  &-text {
    color: #ffffff;
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  &-btn {
    background: transparent;
    color: #ffffff;
    border: 1px solid #fff;
    width: 200px;
    height: 35px;
    cursor: pointer;
  }
}

.profile-top__arrow {
  display: flex;
  color: #fff;
  cursor: pointer;
  svg {
    margin-right: 10px;
  }
}
.confirmed-table {
  display: flex;
  span {
    display: block;
    margin-right: 10px;
  }
  .MuiButton-containedPrimary {
    background-color: #1C4A47;
    text-transform: none;
  }
}
