.admin {
  height: 100vh;
  background: linear-gradient(111.59deg, #1C4A47 1.72%, #1B2E28 104.19%);
  display: flex;
  align-items: center;
  width: 100%;
  &-wrap {
    display: flex;
    height: 100%;
    align-items: center;
    padding: 0 80px;
    width: 100%;
  }
  &-sidebar {
    width: 25%;
    ul {
      li {
        min-height: 100px;
        background-color: #fff;
        border-bottom: 1px solid #ccc;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 30px;
        cursor: pointer;
        font-size: 15px;
        &.active {
          background: #32817C;
          color: #ffffff;
        }
        a {
          color: #32817C;
          font-weight: bold;
        }
      }
    }
  }
  &-content {
    width: 100%;
    margin-left: 1%;
  }

  .MuiTableRow-head {
    th {
      color: #ffffff;
    }
  }
  .MuiTableBody-root {
    background-color: #fff;
  }
  .table-wrap {
    height: 550px;
    overflow-y: auto;
  }
}


.admin-pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  li {
    button {
      color: #ffffff;
    }
  }
}
.change-status-wrap {
  display: flex;
  align-items: center;
;
  svg {
    cursor: pointer;
    path {
      fill: #1C4A47
    }
  }
}



.change-status-modal {
  &__btn {
    text-align: center;
  }
  .MuiSelect-icon {
    color: #ffffff;
  }
  .MuiInput-underline:before {
    border-bottom: 1px solid #fff;
  }
  .MuiSelect-select.MuiSelect-select {
    color: #ffffff;
    input {
      color: #ffffff;
    }
  }
  color: #ffffff;
  div {
    width: 100%;
  }
  .MuiInput-root {
    margin: 10px 0;
    display: block;
  }
  button {
    width: 50%;
    margin-top: 20px;
    color: #ffffff;
    height: 35px;
    border: 1px solid #fff;
    background: transparent;
  }
}


@media screen and(max-width: 992px) {
  .admin {
    &-wrap {
      flex-direction: column;
      justify-content: center;
      padding: 0;
    }
    &-sidebar {
      width: 100%;
      ul {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        height: 50px;
        overflow-x: scroll;
        li {
          padding: 0 10px;
          min-height: 100%;
        }
      }
    }
  }
}
