.ecosystem {
  &-header {
    margin-top: 140px;
    div {
      font-size: 50px;
      color: #ffffff;
      font-weight: 900;
    }
  }
  &-content {
    display: flex;
    justify-content: space-between;
    height: 100%;
  }
  .ecosystem__left-img {
    width: 41%;
    height: 534px;
  }
  .ecosystem__right-title {
    width: 49%;
    margin-left: 11%;
    text-align: right;
    .ecosystem__buttons {
      justify-content: flex-end;
    }
  }
  &__left {
    width: 49%;
    margin-right: 11%;
  }
  &__right {
    height: 534px;
    width: 40%;
  }
  &__title {
    font-weight: 900;
    font-size: 60px;
    color: #ffffff;
  }
  &__desc {
    color: #ffffff;
    font-size: 30px;
    padding: 50px 0;
  }
  &__buttons {
    display: flex;
    align-items: center;
    button {
      &:first-child {
        margin-right: 30px;
      }
    }
  }
  .button-wrapper {
    &:first-child {
      margin-right: 30px;
    }
  }
}



.ecosystem-gallery {
  &__button {
    padding-bottom: 50px;
    margin: 0 auto;
    text-align: center;
    button {
      width: 100px;
      margin: 0 auto;
      text-align: center;
    }
  }
  &__wrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap
  }
  &__item {
    margin-left: 20px;
    margin-top: 60px;
    background: url("../assets/ecosystem1.png") no-repeat;
    background-size: cover;
    width: 300px;
    height: 300px;
    position: relative;
    z-index: 2;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    span {
      display: block;
      margin: 50px;
    }
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: -1;
      height: 100%;
      background-color: #000;
      opacity: 0.5;
    }
  }
}


.gallery-bottom__slider {
  h2 {
    margin-bottom: 50px;
  }
  img {
    width: 400px;
    height: 400px;
  }
}
