.people-card {
  background: radial-gradient(151.64% 120.36% at 79.4% -5.75%, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%);
  backdrop-filter: blur(42px);
  border-radius: 10px;
  position: relative;
  min-height: 250px;
  .people-card-img {
    text-align: center;
  }
  img {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    object-fit: cover;
    width: 125px;
    height: 125px;
    border-radius: 50%;
  }
  button {
    background: transparent;
    border: 1px solid #FFFFFF;
    color: #ffffff;
    padding: 15px 20px;
    margin-top: 30px;
    font-size: 10px;
    font-weight: normal;
    position: relative;
    padding-right: 27px;
    &:first-child {
      margin-right: 15px;
    }
  }
  &-content {
    text-align: center;
  }
  &-title {
    color: #ffffff;
  }
}
