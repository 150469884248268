@media screen and (max-width: 1300px) {
  .content-header {
    padding-left: 65px;
  }
  .sm-container {
    max-width: 968px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 992px) {
  .conference {
    button {
      width: 100%;
    }
  }
  .filter-sphere {
    width: 100% !important;
  }
  .modal-title {
    font-size: 22px;
  }

  .profile {
    &-content {
      width: 100%;
    }
  }
  .category-filters {
    left: 8px !important;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    overflow-x: auto;
    height: 50px;
    .category-item {
      padding: 10px !important;
      margin-left: 10px;
      &:first-child {
        margin-left: 0;
      }
      &__name {
        color: #1c3e3a;
        font-weight: bold;
      }
      &__name,
      &__count {
        font-size: 13px;
      }
    }
  }
  .people-card {
    margin-right: 20px;
    &:last-child {
      margin-right: 0;
    }
  }
  .custom-event-modal-wrap {
    flex-direction: column-reverse;
    display: flex !important;
    img {
      max-width: 100%;
      height: auto;
      object-fit: cover;
    }
    .fablab-img,
    .youthlab-img {
      text-align: center;
      margin-left: 0;
      height: 100%;
      width: 100%;
    }
  }
  .fablab-technologies {
    &__wrap {
      grid-template-columns: repeat(1, 100%) !important;
    }
  }
  .subtitle {
    font-size: 25px;
  }
  h2 {
    font-size: 25px;
  }
  .ecosystem-header {
    div {
      font-size: 25px;
    }
  }
  .startup-detail__info {
    max-width: 100%;
    text-align: center;
    padding: 20px;
  }
  .swiper-container {
    height: 350px;
    .swiper-slide {
      display: flex;
      align-items: center;
    }
  }
  .portfolio-content input {
    width: 100% !important;
  }
  .people-card {
    width: 100% !important;
  }
  .item {
    button {
      margin: 0 auto;
    }
  }
  .item-left {
    div {
      margin-bottom: 25px;
    }
  }
  .badge-wrap {
    position: static;
  }
  .auth-title {
    // display: none;
    font-size: 13px !important;
  }
  .header-content__left {
    padding-top: 0;
  }
  .header-top-mobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 24px;
    padding-left: 15px;
    padding-right: 15px;
    .header-content__languages {
      padding: 0;
      position: relative;
      color: #ffffff;
      &-list {
        position: absolute;
      }
      &-selected {
        font-size: 16px;
        svg {
          width: 12px;
          height: 12px;
        }
        &.active {
          svg {
            transform: rotate(180deg);
          }
        }
      }
      ul {
        display: flex;
        align-items: center;
      }
    }
  }
  .content-header,
  .header-content__left {
    padding-left: 0;
  }

  .container {
    padding-left: 24px;
    padding-right: 24px;
    max-width: 100%;
    width: 100%;
  }
  .header-sidebar {
    display: none;
  }
  .badge-wrap {
    margin-top: 30px;
    .header-content__badge {
      width: 250px;
      height: 250px;
      padding: 50px;
      margin-left: auto;
      margin-right: 30px;
    }
  }
  .main-page {
    &__content {
      .variant-2 {
        .item-wrap {
          flex-direction: column-reverse;
        }
      }
      .item-wrap {
        flex-direction: column;
        .item-right {
          width: 100%;
        }
        .item-left {
          width: 100%;
          div {
            text-align: center;
            font-size: 30px;
          }
        }
      }
    }
  }
  .header-content {
    min-height: 80vh;
    height: 90vh;
    &__title {
      font-size: 22px;
      max-width: 100%;
    }
    &__logo {
      position: static;
      img {
        width: 150px;
      }
    }
  }
  .title {
    font-size: 25px;
    text-align: center;
  }
  .p {
    width: 100%;
    margin-top: 40px;
    text-align: center;
    font-size: 16px;
  }
  .startup-content {
    .title {
      margin-top: 40px;
    }
  }
  .startup-content-item {
    height: 100%;
    padding: 10px 20px;
    &__right {
      font-size: 40px;
      display: flex;
      align-items: center;
    }
    button {
      margin-top: 15px;
      width: 150px;
    }
  }
  .event {
    .title-event {
      font-size: 25px;
    }
  }
  .event-item {
    .event-left-title {
      font-size: 14px;
    }
    .event-left {
      margin-left: 0;
      width: 50%;
      padding-left: 20px;
      margin-right: 40px;
      .event-button {
        width: 100%;
      }
      .event-slot,
      .event-date {
        font-size: 10px;
        span {
          font-size: 10px;
        }
      }
    }
    .event-right {
      width: 50%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      img {
        width: 100px;
        height: 100px;
        margin-right: 0;
      }
      div {
        font-weight: bold;
        font-size: 15px;
        span {
          font-weight: normal;
        }
      }
    }
  }
  .newButton {
    width: 100%;
    margin-top: 20px;
  }
  .event-modal-right {
    width: 100%;
  }
  .event-modal {
    margin: 0;
    padding: 0;
  }
  .event-modal-left {
    form {
      width: 100%;
    }
  }
  .event-modal-wrap {
    display: block;
  }
  .event-modal-title {
    font-size: 20px;
  }
}
.react-sweet-progress-symbol,
.chart-modal__spheres {
  color: #ffffff !important;
}
