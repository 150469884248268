.startup-content {
  .title {
    margin-bottom: 50px;
  }
  &-item {
    background-color: #fff;
    padding: 10px 50px;
    opacity: 0.9;
    display: flex;
    justify-content: space-between;
    height: 275px;
    margin-bottom: 50px;
    &__left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    span {
      color: #808E9B;
      font-size: 20px;
      margin-bottom: 20px;
      display: block;
    }
    .concurs {
      font-size: 15px;
    }
    .name-courses {
      font-weight: bold;
      font-size: 20px;
      margin: 5px 0;
    }
    .deadline {
      font-size: 17px;
    }
    button {
      border: 0;
      background-color: transparent;
      border: 1px solid #1C4A47;
      padding: 10px 0;
      width: 250px;
      position: relative;
      margin-bottom:25px;
    }
    &__right {
      font-size: 250px;
      color: #C4C4C4;
    }
  }
}
