* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  overflow-x: hidden;
  background: linear-gradient(64.79deg, #1c4946 -1.84%, #1b2e28 111.12%);
}

.container {
  max-width: 1300px;
  margin: 0 auto;
}

ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
}

.p-100 {
  padding: 100px 0;
}

.p-50 {
  padding: 50px 0;
}

.img {
  max-width: 100%;
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.button-wrapper {
  button {
    padding: 12px 30px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background: transparent;
    color: #ffffff;
    border: 1px solid #ffffff;
    cursor: pointer;
    span {
      display: block;
      white-space: nowrap;
      &:first-child {
        margin-right: 15px;
      }
      &:last-child {
        height: 1px;
        width: 30%;
        background-color: #fff;
      }
    }
  }
  &.fill-btn {
    button {
      padding: 12px 30px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      background: #fff;
      color: #1b4441;
      border: 2px solid #ffffff;

      cursor: pointer;
      span {
        display: block;
        white-space: nowrap;
        &:first-child {
          margin-right: 15px;
        }
        &:last-child {
          height: 1px;
          width: 30%;
          background-color: #1b4441;
        }
      }
    }
  }
  button {
    font-weight: 600;
  }
}

h2 {
  color: #ffffff;
  font-weight: bold;
  font-size: 40px;
}

.title {
  font-size: 60px;
  font-weight: bold;
  margin-top: 140px;
  color: #ffffff;
}
.p {
  color: #ffffff;
  width: 70%;
  margin-top: 40px;
}

.subtitle-bold {
  font-weight: 600;

  font-size: 40px;
  color: #ffffff;
}
.subtitle {
  font-size: 40px;
  color: #ffffff;
}
.subtitle-desc {
  font-size: 25px;
  color: #ffffff;
}

.d-flex {
  display: flex;
  flex-wrap: wrap;
}

.padding-container {
  padding: 50px;
}

.MuiDialog-paper {
  background: linear-gradient(111.59deg, #1c4a47 1.72%, #1b2e28 104.19%);
}

.MuiDialog-paperWidthSm {
  max-width: 100% !important;
  width: 90% !important;
}

.textfield {
  width: 100%;
  label {
    display: block;
    color: #ffffff;
    font-size: 16px;
    margin-bottom: 15px;
  }
  textarea {
    padding-top: 15px;
  }
  input,
  textarea,
  select {
    background: rgba(255, 255, 255, 0.34);
    border: 0;
    height: 50px;
    color: #c4c4c4;
    padding-left: 10px;
    outline: none;
    width: 100%;
    &::placeholder {
      color: #c4c4c4;
    }
  }
  option {
    background: #1c4946;
    color: #ffffff;
  }
}

.close-icon {
  text-align: right;
  margin: 20px;
  position: absolute;
  right: 0;
}
.modal-title {
  font-size: 60px;
  font-weight: bold;
  color: #ffffff;
}

.customWidthModal {
  .MuiDialog-paperWidthSm {
    width: 500px !important;
  }
  svg {
    width: 25px;
  }
  form {
    width: 85%;
    margin: 0 auto;
  }
}

.dialog-modal {
  .MuiDialog-paperWidthSm {
    width: 550px !important;
    svg {
      width: 30px;
      height: 30px;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
  }
  .close-icon {
    top: 0;
  }
  .MuiDialogContent-root {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .register-success {
    color: #ffffff;
    height: 100%;
    div {
      font-size: 14px;
    }
  }
}

@media screen and (min-width: 992px) {
  .hidden-desktop {
    display: none !important;
  }
}
@media screen and (max-width: 992px) {
  .hidden-mobile {
    display: none !important;
  }

  .chart-wrapper {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    canvas {
      height: 350px !important;
      margin: 0 auto;
      width: 100% !important;
    }
  }
}

.startup-map {
  position: relative;
  display: inline-block;
  .first {
    position: absolute;
    right: 0;
  }
}

.chartClass {
  .mapIcon {
    width: 100% !important;
    height: 100% !important;
  }
  .MuiDialog-paperWidthSm,
  .MuiDialogContent-root {
    display: block;
  }
}

.progress_name {
  color: #ffffff;
  margin-top: 15px;
  margin-bottom: 10px;
}

.customClass {
  .MuiDialogContent-root {
    align-items: flex-start !important;
  }
}
.pagination-item {
  color: #ffffff !important;
}

.pagination-bar {
  justify-content: center;
}

.success-modal {
  div {
    color: #ffffff;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  p {
    font-size: 14px;
    color: #ffffff;
  }
}

.courses-item {
  width: 100%;
  height: 100%;
  background-size: cover !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  z-index: 5;
  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.7;
    z-index: -1;
  }
  &__date {
    font-size: 13px;
    opacity: 0.8;
    margin-bottom: 20px;
  }
  &__title {
    font-size: 22px;
    font-weight: bold;
  }
  &__wrap {
    max-width: 80%;
    margin: 0 auto;
    text-align: center;
    button {
      width: 100%;
      &:before {
        right: 10px;
      }
    }
  }
  &__desc {
    font-size: 18px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.user-type {
  color: #ffffff;
  font-size: 14px;
  padding: 10px 0;
  text-align: right;
  padding-right: 10px;
}

.my-cards {
  background-color: #fff;
  padding: 30px 50px;
  max-width: 70%;
  margin: 0 auto;
  &__name {
    margin-bottom: 10px;
  }
  margin-top: 20px;
  &__person {
    font-size: 15px;
    margin-bottom: 20px;
  }
  button {
    background: transparent;
    border: 1px solid #1c4a47;
    font-size: 15px;
    color: #1c4a47;
    height: 50px;
    padding: 0 25px;
  }
  &__desc {
    font-size: 14px;
    margin-bottom: 20px;
  }
  &__sum {
    margin-bottom: 20px;
    font-size: 15px;
    font-weight: bold;
  }
  &__title {
    font-weight: bold;
    margin-bottom: 20px;
    font-size: 15px;
  }
}

.quit-app {
  text-align: center;
  margin-top: 35px;
  text-decoration: underline;
  color: #ffffff;
  cursor: pointer;
}

.map-wrapper {
  position: relative;
  .category-filters {
    position: absolute;
    z-index: 99999;
    left: 50px;
    top: 25px;
    .category-item {
      background-color: #fff;
      margin-top: 10px;
      position: relative;
      display: flex;
      justify-content: space-between;
      padding: 20px 30px;
      &__name {
        margin-right: 30px;
      }
      &__color {
        position: absolute;
        height: 100%;
        width: 5px;
        left: 0;
        top: 0;
      }
    }
  }
}

.btnStyle {
  color: #1c4a47;
  padding: 10px 30px;
  background: transparent;
  border: 1px solid #1c4a47;
  cursor: pointer;
  font-weight: 500;
}
.btnStyleTransparent {
  color: #fff;
  padding: 10px 30px;
  background: transparent;
  border: 1px solid #fff;
  cursor: pointer;
  font-weight: 500;
}

.profile-btn {
  margin-top: 30px;
}

.profile-role {
  font-size: 14px;
  color: #000000;
}

.profile {
  .btnStyleTransparent {
    margin-top: 50px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

button:disabled {
  background: #ccc !important;
}

.presentation-profile {
  margin-bottom: 20px;
  a {
    color: #1c4a47 !important;
  }
}
.profile-upload-presentation {
  a {
    color: #1c4a47 !important;
  }
}
.event-content {
  background-color: #fff;
}
