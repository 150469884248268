.startup-detail {
  .profile-top {
    margin-left: 0;
    text-align: center;
    margin-top: 20px;
  }
  &__fullName {
    color: #ffffff;
    margin-top: 10px;
  }
  &__info {
    min-width: 60%;
    max-width: 70%;
    margin-left: auto;
    padding: 60px 75px;
    margin-right: auto;
    background-color: #fff;
    margin-top: 60px;
    button {
      color: #1C4A47!important;
      border: 1px solid #1C4A47!important;
      background: transparent;
      width: 161px;
      height: 40px;
      margin-top: 30px;
      line-height: 40px;
    }
  }
  &__sphere {
    font-weight: bold;
    margin-bottom: 35px;
  }
  &__idea {
    margin-bottom: 30px;
  }
  &__price {
    font-weight: bold;
  }
  &__img {
    margin: 0 auto;
  }
}


.startup-feedback {
  position: relative;
  svg {
    position: absolute;
    cursor: pointer;
    top: 20px;
    right: 20px;
    width: 30px;
    height: 30px;
  }
  .MuiDialog-paperWidthSm {
    max-width: 600px!important;
    padding-left: 90px;
    padding-right: 90px;
  }
  .MuiDialog-paper {
    overflow-y: initial!important;
  }
  span {
    color: #ffffff;
  }
  &__field {
    div {
      color: #ffffff;
      margin-bottom: 20px;
      margin-top: 20px;
    }
    button {
      border: 1px solid #fff;
      color: #fff;
      width: 50%;
      height: 40px;
      margin-top: 30px;
      margin-left: auto;
      margin-right: auto;
      background: transparent;
    }
  }
  textarea {
    width: 100%;
    min-height: 135px;
    background: rgba(255, 255, 255, 0.34);
    color: #ffffff;
    outline: none;
  }
  &__profile {
    position: relative;
    text-align: center;
    img {
      margin-top: -100px;
      width: 200px;
      height: 200px;
    }
  }
}
