.header-content {
  height: 100vh;
  background-image: url("../assets/bg.png");
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  position: relative;
  z-index: 1;
  &__languages {
    padding-left: 65px;
    padding-top: 50px;
    position: absolute;
    bottom: 5%;
  }
  &__left {
    padding-left: 65px;
    padding-top: 50px;
    display: flex;
    align-items: flex-start;
    .auth-title {
      font-size: 15px;
      color: #ffffff;
      margin-left: 15px;
    }
  }
  &__logo {
    position: absolute;
    right: 4%;
    z-index: 999;
    top: 5%;
    img {
      max-width: 400px;
      margin-left: auto;
    }
  }
  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.5;
    z-index: -1;
  }

  &__title {
    font-size: 45px;
    font-weight: 900;
    color: #ffffff;
    margin-top: 115px;
    max-width: 50%;
    span {
      text-shadow: 1px 4px 4px rgba(0, 0, 0, 0.4);
      color: rgba(77, 120, 158, 1);
    }
  }
  &__btn {
    margin-top: 55px;
    button {
      background-color: #fff;
      color: #000000;
      font-weight: 600;
      border: 0;
      height: 55px;
      width: 220px;
    }
  }
  &__badge {
    background: url("../assets/mainFigure.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 300px;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    .header-badge {
      text-align: center;
      &__date {
        font-size: 14px;
        font-weight: 500;
        color: #223e49;
        margin-bottom: 5px;
      }
      &__title {
        font-weight: bold;
        font-size: 22px;
        margin-bottom: 15px;
      }
      &__subtitle {
        font-size: 16px;
        text-decoration: underline;
        color: #808e9b;
      }
      &__top {
        margin-bottom: 50px;
        div {
          font-weight: bold;
        }
        span {
          color: #808e9b;
          font-size: 17px;
        }
      }
      &__bottom {
        color: #808e9b;
        font-size: 13px;
        text-decoration: underline;
      }
    }
  }
}

.header-sidebar {
  text-align: center;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  right: 0;
  width: 14%;
  top: 0;
  &__wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    position: relative;
    .header-sidebar__socials {
      position: absolute;
      bottom: 2%;
      left: 50%;
      transform: translateX(-50%);
      img {
        display: block;
        width: 30px;
        height: 30px;
      }
    }
  }
  &-hamburger {
    width: 25px;
    span {
      display: block;
      height: 2px;
      background-color: #fff;
      width: 100%;
      margin-bottom: 5px;
    }
  }
  &-languages {
    color: #ffffff;
    li {
      margin-bottom: 10px;
      text-transform: uppercase;
      cursor: pointer;
      &.active {
        color: #fff;
        font-weight: bold;
      }
    }
  }
  &__socials {
    padding-bottom: 36px;
  }
}

.badge-wrap {
  position: absolute;
  left: 60%;
  bottom: 5%;
}

.overlay {
  background: linear-gradient(90deg, #1c4a47 30.33%, rgba(28, 74, 71, 0) 100%);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transform: translateX(-10000px);
  transition: 0.5s transform ease, opacity 0.5s;
  opacity: 0;
  z-index: -1;
  &.overlay-animation {
    opacity: 1;
    transform: translateX(0);
    z-index: 1;
    transition: 0.5s transform ease, opacity 0.5s;
  }
}

.menu-header {
  position: absolute;
  z-index: 999;
  transform: translateX(-1000px);
  top: 66px;
  left: 66px;
  &.menu-open {
    display: block;
    transform: translateX(0);
    transition: 1s ease;
  }
  &__close {
    cursor: pointer;
    top: 0;
    svg {
      width: 35px;
      height: 35px;
    }
  }
  .menu-wrap {
    margin-top: 20px;
    color: #ffffff;
    .menu-item {
      display: flex;
      align-items: center;
    }
    li {
      margin-bottom: 5px;
      cursor: pointer;
      position: relative;
      svg {
        margin-left: 10px;
        height: 100%;
        display: block;
      }
      .active {
        svg {
          transform: rotate(180deg);
        }
      }
      a {
        text-decoration: none;
        color: #ffffff;
      }
      div + ul {
        margin-left: 40px;
        position: relative;
        transition: visibility 0.3s linear, opacity 0.3s linear,
          height 0.3s linear;
        &:before {
          content: "";
          position: absolute;
          left: -15px;
          height: 100%;
          width: 1px;
          background-color: #fff;
        }
        li {
          font-size: 15px;
        }
      }
    }
  }
}
